import React,{useContext, useRef,useState} from 'react'

import { ApiService } from '../../../../services/apiUtility'
import { AppContext } from '../../../../context/AppContextProvider'
import { useNavigate } from 'react-router-dom'
import { BACKEND_HOST } from '../../../../constants/backend'
import PercentUploadLoader from '../../../Loader/PercentUploadLoader'
import axios from 'axios'
import ErrorTable from './ErrorTable'

const UploadProductSheet = () => {
    const {setShowNotification} = useContext(AppContext)
    const [showUploadLoader, setShowUploadLoader] = useState(false)
    const [percent,setPercent] = useState(0)
    const navigate = useNavigate()
    const inputRef = useRef(null)
    const [showErrorTable,setShowErrorTable] = useState(false)
    const [errorData,setErrorData] = useState({})

    const handleDragOver = (e)=>{
        e.preventDefault()
    }

    const handleDrop = async (event)=>{
        event.preventDefault()
        const fileList = event.dataTransfer.files;
        const fileListArray = Array.from(fileList)
        const uploadedSheet = fileListArray[0]
        if(uploadedSheet.type.includes('csv')){
            uploadProductSheet(uploadedSheet)
        }else{
            setShowNotification({open:true,message:'Please drag and drop CSV file only',type:'error',hideAutomatically:true})
        }
    }

    const uploadProductSheet = async(uploadedSheet)=>{
        setShowUploadLoader(true)
        const formData = new FormData();
        formData.append("file", uploadedSheet);
        try {
            const response = await axios.post(`${BACKEND_HOST}/product/beauty/add/${ApiService.brandId}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                onUploadProgress: uploadProgress
            });
            if (response && response.status==200) {
                setShowNotification({ open: true, message: response?.data?.message, type: 'success',hideAutomatically:true });
            } else {
                
            }
            setTimeout(()=>{
                setShowUploadLoader(false);
                setPercent(0)
            },800)
        } catch (error) {
            setShowUploadLoader(false);
            setPercent(0)
            setShowErrorTable(true)
            setErrorData({data:error.response.data.data,fileName:uploadedSheet.name})
        }
        
    }

    const uploadProgress =(progressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        setPercent(percentCompleted);
    }

    const handleBrowseFiles = async (event)=>{
        const fileList=event.target.files
        const fileListArray = Array.from(fileList)
        event.target.value=null
        uploadProductSheet(fileListArray[0])
    }

    const downloadProductSheet = async ()=>{
        const response = await ApiService.downloadProductSheet()
        if(response.status==200){
            const productSheet = await response.text()
            const blob = new Blob([productSheet], { type: 'text/csv' });
            const url = window.URL.createObjectURL(blob)
            const a = document.createElement('a')
            a.setAttribute('href', url)
            a.setAttribute('download', 'productSheet.csv');
            a.click() 
        } else if(response.status==403){
            localStorage.clear()
            navigate('/authentication/sign-in')
            setShowNotification({open:true,message:'Unauthorized! You have been logged out',type:'error',hideAutomatically:true})
        }else{
            setShowNotification({open:true,message:'There is some error while downloading product sheet',type:'error',hideAutomatically:true})
        }
    }

    const downloadSampleProductSheet = async ()=>{
        const filePath = require('../../../../assets/Sample_Sheet.csv')
        try {
            const response = await fetch(filePath);
            if (!response.ok) {
              throw new Error('File not found');
            }
            const fileBlob = await response.blob();
            const url = window.URL.createObjectURL(fileBlob);
            const a = document.createElement('a');
            a.href = url;
            a.setAttribute('download', 'Sample_Sheet.csv');
            a.click();
            window.URL.revokeObjectURL(url);
          } catch (error) {
            setShowNotification({open:true,message:'No sample sheet to download',type:'error',hideAutomatically:true})
          }
    }

    const handleClosePopup = ()=>{
        setShowErrorTable(false)
    }
    return (
        <div className='flex flex-col gap-[20px] px-[40px] pt-[20px] bg-[#fff] rounded mt-[20px]' >
            <div className='text-[16px] font-medium text-[#666085]'>Upload Product Sheet</div>
            <div className=' flex flex-col gap-[40px] justify-evenly items-center w-full'>
                <div className='py-[40px] border-dashed rounded border-[2px] border-[#DCDBDB] 
                         w-[100%] h-full  flex 
                        flex-col items-center justify-center font-medium gap-[10px]'
                        onDragOver={handleDragOver}
                        onDrop={handleDrop}
                >
                    <div className='py-[10px] text-center max-w-[40px]'>
                                    <img src={require("../../../../assets/images/sheet_upload.jpg")} alt="" />
                            </div>
                            <input type="file" hidden
                                onChange={handleBrowseFiles}
                                ref={inputRef} accept=".csv"
                            />
                            <div className='text-center text-[16px] px-[45px] whitespace-nowrap'>
                                Drop & Drop your file here
                            </div> 
                            <div>Or</div>
                            <div>
                                <button className='cursor-pointer px-[40px] py-[8px] border-[1px] border-[#130F24] rounded text-[16px]'
                                        onClick={()=>{
                                            inputRef.current.click()
                                        }}>
                                            Browse files
                                </button>
                            </div>
                            {
                                showUploadLoader?
                                <PercentUploadLoader percent={percent}/>:''
                            }
                            
                </div>
                <div className='flex w-full justify-center gap-[31px] mb-[15px]'>
                    <div className='flex flex-col justify-center'>
                        <button className='flex items-center gap-[10px] text-[14px] cursor-pointer bg-black text-white border-0 py-[8px] px-[35px]'
                                    onClick={downloadSampleProductSheet}
                            >
                            <img src={require('../../../../assets/images/download.png')} width='15px' height='15px' alt="" />    
                            Sample CSV
                        </button>
                    </div> 
                    <div className='flex flex-col justify-center'>
                        <button className='flex text-[14px] items-center gap-[10px] cursor-pointer  border-[1px] border-black py-[8px] px-[35px]'
                                    onClick={downloadProductSheet}
                            >
                            <img src={require('../../../../assets/images/download1.png')} width='15px' height='15px' alt="" />    
                            Inventory
                        </button>
                    </div>
                </div>
                  
            </div>
            {showErrorTable?<ErrorTable errorData={errorData} handleClosePopup = {handleClosePopup}/>:''}
        </div>
    )
}

export default UploadProductSheet