import React from 'react';
import { AiOutlineCloseCircle } from "react-icons/ai";

const ErrorTable = ({ errorData,handleClosePopup=()=>{} }) => {
  return (
    <div className='absolute top-0 left-0 w-full h-full bg-[rgb(0,0,0,0.1)] flex justify-center items-center' onClick={handleClosePopup}>
      <div className='p-[40px] bg-white rounded shadow-lg w-[80%] relative'>
        <div className='absolute top-[5px] right-[5px] cursor-pointer mb-[5px]' onClick={handleClosePopup}>
            <AiOutlineCloseCircle size={22} />
         </div>
         <div className='border-[10px] border-[rgba(254,0,125,0.05)] p-[20px] rounded flex justify-between'>
            <div className='flex gap-[8px] items-center'>
                <img src={require('../../../../assets/images/file-icon.png')} alt="" className='w-[20px] h-[20px]'/>
                {errorData.fileName}
            </div>
            <div className='text-red-500'>{errorData.data.length} ERRORS</div>
         </div>
        <h2 className='my-[20px] text-xl font-semibold text-red-400'>Error Details</h2>
        <table className='table-auto border-collapse w-full'>
          <thead>
            <tr className='bg-gray-100'>
              <th className='border px-4 py-2'>S.No</th>
              <th className='border px-4 py-2'>Error Message</th>
            </tr>
          </thead>
          <tbody>
              {errorData.data.map((error, index) => (
                <tr key={index} className='even:bg-gray-100'>
                  <td className='border px-4 py-2 text-center'>{index + 1}.</td>
                  <td className='border px-4 py-2 font-light'>{error}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ErrorTable;
