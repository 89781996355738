import React, { useContext } from 'react'
import { AppContext } from '../../context/AppContextProvider'


const SkinAnalyticsDashboardPage = () => {
    const { brandSettings } = useContext(AppContext)
    return (
        <div className='w-full h-full'>
            <iframe  id="skinAnalyticDashboardPage" title='Skin Analytics Page' className="w-full frame aspect-square"
                src={brandSettings?.setting_meta_json?.skinAnalysis?.analyticEmbedLink || ""}>
            </iframe>
        </div>
    )
}

export default SkinAnalyticsDashboardPage