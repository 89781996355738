import React, { useContext, useEffect, useState } from 'react'
import { ApiService } from '../../../../services/apiUtility'
import { Categories } from '../../ManageInventory/Categories'
import { SearchBar } from '../../ManageInventory/SearchBar'
import { Actions } from '../../ManageInventory/Actions'
import DeleteConfirmation from '../../../Dialog/DeleteConfirmation'
import { AppContext } from '../../../../context/AppContextProvider'
import LinearLoader from '../../../Loader/LinearLoader'
import LooksCard from './LooksCard'
import Pagination from '../../ManageInventory/Pagination'
import { ProductType } from '../../ManageInventory/ProductType';
import LooksProductTable from './ManageLooksProducts/LooksProductTable'
import { EntriesSelection } from '../../ManageInventory/EntriesSelection'
import { useNavigate } from 'react-router-dom'

const ManageLooks = ({ collection_key }) => {
    const [looksCategories, setLooksCategories] = useState([])
    const [selectedLooksCategory, setSelectedLooksCategory] = useState(null)
    const { openDeleteConfirmation, setOpenDeleteConfirmation,setShowNotification } = useContext(AppContext)

    const [looksCollection, setLooksCollection] = useState([])

    const [selectedCategoryLooks, setSelectedCategoryLooks] = useState([])
    const [selectedCategoryLooksCopy, setSelectedCategoryLooksCopy] = useState([])
    const [selectedLooks, setSelectedLooks] = useState([])
    const [isSelectAll, setIsSelectAll] = useState(false)

    const [currentPage, setCurrentPage] = useState(1);
    const productsPerPage = 20;

    const [searchValue, setSearchValue] = useState('')
    const [searchResults, setSearchResults] = useState([])

    const [selectedLook, setSelectedLook] = useState({})
    const [showProductsTable, setShowProductsTable] = useState(false)

    const [showLoader, setShowLoader] = useState(false)

    const [statusType, setStatusType] = useState('all')

    const [entries, setEntries] = useState([])
    const navigate = useNavigate()

    useEffect(() => {
        fetchLooksCategory()
    }, [])

    const fetchLooksCategory = async (category) => {
        setShowLoader(true)
        const looksInCollection = await ApiService.getLooksInCollection()
        let categories = []
        for (let category of looksInCollection.lookCollection) {
            const categoryObj = {
                value: category.collectionKey,
                label: category.collectionLabel
            }
            categories.push(categoryObj)
        }
        if (Object.keys(looksInCollection).length > 0) {
            if (looksInCollection.lookCollection.length > 0) {
                setLooksCollection(looksInCollection.lookCollection)

                const selectedCategoryLooks = looksInCollection.lookCollection.filter((collection) => {
                    if (category) {
                        return collection.collectionKey == category.value
                    } else if (collection_key) {
                        return collection.collectionKey == collection_key
                    } else {
                        return collection.collectionKey == categories[0].value
                    }
                })
                setSelectedCategoryLooks(selectedCategoryLooks[0].looksInCollection)
                setSelectedCategoryLooksCopy(selectedCategoryLooks[0].looksInCollection)
                createEntriesOptions(selectedCategoryLooks[0].looksInCollection)
                setLooksCategories(categories)
                if (category) {
                    setSelectedLooksCategory(category)
                } else if (collection_key) {
                    const selectedCategory = categories.filter((category) => {
                        return category.value == collection_key
                    })
                    setSelectedLooksCategory(selectedCategory[0])
                } else {
                    setSelectedLooksCategory(categories[0])
                }
                setStatusType('all')
            }
        }
        setShowLoader(false)
    }

    const handleCategoryOnClick = (category) => {
        setCurrentPage(1)
        const selectedCategoryLooks = looksCollection.filter((collection) => {
            return collection.collectionKey == category.value
        })
        setSelectedCategoryLooks(selectedCategoryLooks[0].looksInCollection)
        setSelectedCategoryLooksCopy(selectedCategoryLooks[0].looksInCollection)
        createEntriesOptions(selectedCategoryLooks[0].looksInCollection)
        setSelectedLooksCategory(category)
        handleSearch(searchValue, selectedCategoryLooks[0].looksInCollection)
        handleUnSelectLooks()
    }

    const handleSearchFilter = (searchText) => {
        setCurrentPage(1)
        setSearchValue(searchText)
        handleSearch(searchText, selectedCategoryLooksCopy)
    }

    const handleSearch = (searchText, looksInCollection) => {
        const filteredResults = looksInCollection.filter((looks) => {
            if (statusType == 'all') {
                return looks.key.toLowerCase().includes(searchText.toLowerCase()) || looks.label.toLowerCase().includes(searchText.toLowerCase())
            } else {
                return looks.key.toLowerCase().includes(searchText.toLowerCase()) && String(looks.status) == statusType || looks.label.toLowerCase().includes(searchText.toLowerCase()) && String(looks.status) == statusType
            }
        })
        setSelectedCategoryLooks(filteredResults)
        setSearchResults(filteredResults)
        createEntriesOptions(filteredResults)
    }

    const handleProductStatus = (val) => {
        setCurrentPage(1)
        setStatusType(val)
        const filteredResults = selectedCategoryLooksCopy.filter((looks) => {
            if (val == 'all') {
                return looks.key.toLowerCase().includes(searchValue.toLowerCase())
            } else {
                return looks.key.toLowerCase().includes(searchValue.toLowerCase()) && String(looks.status) == val
            }
        })

        setSelectedCategoryLooks(filteredResults)
        setSearchResults(filteredResults)
        createEntriesOptions(filteredResults)
        handleUnSelectLooks()
    }

    const handleLooksStatus = (look,value)=>{
        const looks = [...selectedCategoryLooks]
        const index =looks.findIndex((ele)=>ele.key==look.key)
        if(index>-1)
        {
            looks[index].status=value
        }
        setSelectedCategoryLooks(looks)
        setSelectedCategoryLooksCopy(looks)
    }

    const updateDataOnPageChange = (data, firstIndex, lastIndex) => {
        const looks = data.slice(firstIndex, lastIndex)
        setSelectedCategoryLooks(looks)
    }

    const handleCheckboxClick = (e) => {
        const { id, checked } = e.target;
        const look = JSON.parse(e.target.dataset.look)
        if (!checked) {
            const updatedSelectedLooks = selectedLooks.filter(look => {
                return look.key != id
            })
            setSelectedLooks(updatedSelectedLooks);
        } else {
            const updatedSelectedLooks = [...selectedLooks, look]
            setSelectedLooks(updatedSelectedLooks);
        }
    };

    const handleUnSelectLooks = () => {
        setIsSelectAll(false)
        setSelectedLooks([])
    }

    const onDeleteConfirm = async () => {
        const looks = [...selectedCategoryLooks]
        const index =looks.findIndex((ele)=>ele.id==selectedLook.id)
        
        const res = await ApiService.deleteLook(selectedLook.id)
        if (res && res.status == 200) {
            if(index>-1)
            {
                looks.splice(index, 1);
            }
            setSelectedCategoryLooks(looks)
            setSelectedCategoryLooksCopy(looks)
            setShowNotification({ open: true, message: 'Look deleted successfully', type: 'success',hideAutomatically:true })
          } else if (res && res.status == 403) {
            localStorage.clear()
            navigate('/authentication/sign-in')
            setShowNotification({ open: true, message: 'Unauthorized! You have been logged out', type: 'error',hideAutomatically:true })
          } else {
            setShowNotification({open:true,message:'There is some error.',type:'error'})
          }
    }

    const handleShowOrHideProductsTable = (val, look) => {
        setShowProductsTable(val)
        if (val) {
            setSelectedLook(look)
        } else {
            setSelectedLook({})
        }
    }

    const createEntriesOptions = (lookData) => {
        const entriesArray = []
        let entry
        for (let i = 1; i < Math.ceil(lookData.length / productsPerPage) + 1; i++) {
            if (i == Math.ceil(lookData.length / productsPerPage)) {
                entry = (i - 1) * productsPerPage + 1 + ' - ' + lookData.length
            } else {
                entry = (i - 1) * productsPerPage + 1 + ' - ' + i * productsPerPage
            }

            entriesArray.push(entry)
        }
        setEntries(entriesArray)
    }

    const handleEntrySelection = (value) => {
        setCurrentPage(value)
    }

    const handleSelectAll = () => {
        setIsSelectAll(!isSelectAll);
        if (isSelectAll) {
            handleUnSelectLooks()
        } else {
            if (statusType == 'all') {
                const selectedLooks = selectedCategoryLooksCopy.map(look => {
                    return look
                })
                setSelectedLooks(selectedLooks);
            } else {
                const selectedLooks = searchResults.map(look => {
                    return look
                })
                setSelectedLooks(selectedLooks);
            }
        }
    }

    const getNumberOfProducts = (category)=>{
        const looksInCollection = looksCollection.filter((look) => look.collectionKey === category.value);

        if (looksInCollection.length === 0) return 0;

        let filteredLooks = looksInCollection[0].looksInCollection;
        if (statusType !== 'all') {
            filteredLooks = filteredLooks.filter((look) => String(look.status) === statusType);
        }
        if (searchValue !== '') {
            filteredLooks = filteredLooks.filter((look) => 
                look.key.toLowerCase().includes(searchValue.toLowerCase()) || 
                look.label.toLowerCase().includes(searchValue.toLowerCase())
            );
        }
        return filteredLooks.length;
      }

      const getTotalCount = ()=>{
        let count=0
        looksCollection && looksCollection.length>0 && looksCollection.map((look)=>{
            count=count+look.looksInCollection.length
        })
        return count
      }

    return (
        <div className='h-[95%]'>
            <div className='text-[16px] font-semibold py-[19px]'>
                MANAGE LOOKS ({getTotalCount()})
            </div>
            <div className='text-[16px] bg-[#fff] px-[20px] rounded w-full min-h-[95%] h-max'>
                {
                    openDeleteConfirmation ?
                        <DeleteConfirmation onDeleteConfirm={onDeleteConfirm} /> : ''
                }
                {
                    looksCategories.length > 0 ?
                        !showProductsTable ?
                            (
                                <div className='w-full h-full flex flex-col items-center'>
                                    <div className='sticky top-[-2px] w-full z-10'>
                                        <div className='bg-white'>
                                            <div className='pt-[10px] pb-[20px] w-full border-b-[1px] h-max border-[#0001]'>
                                                <div className='flex pb-[2px] gap-[10px] overflow-x-auto container2 mb-[40px] h-[50px]'>
                                                    {
                                                        looksCategories.length > 0 && looksCategories.map((category, index) => {
                                                            return <Categories category={category} key={index} selectedCategory={selectedLooksCategory} handleCategoryOnClick={handleCategoryOnClick} type={'looks'} count={getNumberOfProducts(category)}/>
                                                        })
                                                    }
                                                </div>
                                                {
                                                    
                                                        <div className='flex justify-center text-[14px] font-normal gap-[40px]'>
                                                            {
                                                                entries.length > 0 ?
                                                                    <div className='flex gap-[10px] items-center'>
                                                                        <span className=''>Show</span>
                                                                        <EntriesSelection entries={entries} handleEntrySelection={handleEntrySelection} />
                                                                        <span className=''>entries</span>
                                                                    </div> : ''
                                                            }

                                                            <div className='flex gap-[10px]  items-center'>
                                                                <span className=''>Status:</span>
                                                                <ProductType handleProductStatus={handleProductStatus} type='Looks' statusType={statusType} />
                                                            </div>
                                                            <Actions
                                                                selectedProducts={selectedLooks}
                                                                handleUnSelectProducts={handleUnSelectLooks}
                                                                fetchCategories={fetchLooksCategory}
                                                                type='looks'
                                                                selectedLooksCategory={selectedLooksCategory}
                                                            />
                                                            <SearchBar handleSearchFilter={handleSearchFilter} />
                                                        </div>
                                                }

                                            </div>
                                        </div>
                                    </div>
                                    {
                                        selectedCategoryLooks && selectedCategoryLooks.length > 0 ?
                                            <div className='w-full flex justify-between items-center mt-[20px]'>
                                                <div className='flex items-center gap-[10px]'>
                                                    <span className='text-[16px] font-bold'>Select All: </span>
                                                    <input
                                                        type='checkbox'
                                                        onChange={handleSelectAll}
                                                        checked={isSelectAll}
                                                        className='w-[20px] h-[20px] accent-white checkbox'
                                                    />
                                                </div>
                                                <div>
                                                    Currently Selected {selectedLooksCategory.label} Looks: {selectedLooks.length}
                                                </div>

                                            </div> : ''
                                    }

                                    <div className='w-full'>
                                        <div className='grid grid-cols-[repeat(auto-fit,280px)] gap-x-[20px] gap-y-[40px] my-[20px] justify-evenly w-full h-full'>
                                            {
                                                selectedCategoryLooks && selectedCategoryLooks.length > 0 ? selectedCategoryLooks.map((look, index) => {
                                                    return <LooksCard look={look} key={index}
                                                        handleCheckboxClick={handleCheckboxClick}
                                                        type='looks'
                                                        selectedLooks={selectedLooks}
                                                        handleLooksStatus={handleLooksStatus}
                                                        selectedCategory={selectedLooksCategory}
                                                        setSelectedLook={setSelectedLook}
                                                    />
                                                }) :
                                                    <div className='flex justify-center w-full h-full'>No Look found.</div>
                                            }
                                        </div>
                                    </div>
                                    {
                                        selectedCategoryLooksCopy && selectedCategoryLooksCopy.length > 0 ?
                                            (
                                                <Pagination
                                                    updateDataOnPageChange={updateDataOnPageChange}
                                                    searchText={searchValue}
                                                    searchResults={searchResults}
                                                    currentPage={currentPage}
                                                    setCurrentPage={setCurrentPage}
                                                    recordsPerPage={productsPerPage}
                                                    type={'looks'}
                                                    statusType={statusType}
                                                    selectedCategoryLooks={selectedCategoryLooksCopy}
                                                />
                                            ) : ''
                                    }
                                </div>
                            ) : <LooksProductTable look={selectedLook} handleShowOrHideProductsTable={handleShowOrHideProductsTable} />
                        :
                        showLoader ? <LinearLoader />
                            : <div className='py-[20px] flex justify-center'>No looks found.</div>
                }

            </div>
        </div>
    )
}

export default ManageLooks