import React from 'react'

export const Categories = ({category,selectedCategory,handleCategoryOnClick=()=>{},type,count})=>{
    let categoryLabel = type=='products'?category.label:type=='variants'?category.categoryLabel:category.label
    let selectedCategoryLabel = type=='products'?selectedCategory.category.label:type=='variants'?selectedCategory.categoryLabel:selectedCategory.label
    const handleCategoryClick = (category)=>{
        handleCategoryOnClick(category)
    }
    return (
        <div className={`flex items-center justify-center rounded
            p-[20px] capitalize 
            font-medium cursor-pointer ${ selectedCategoryLabel===categoryLabel?'bg-black text-white':'text-[#717474]'}`} 
            onClick={()=>handleCategoryClick(category)}>
                <span className='whitespace-nowrap font-[16px]'>{categoryLabel}</span>{type=='looks'?` (${count})`:''}
        </div>
    )
}