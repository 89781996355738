import './App.css';
import { BrowserRouter, Route, Routes,Navigate } from 'react-router-dom';
import SignInPage from './Pages/authPages/signIn/SignInPage';
import ForgetPasswordPage from './Pages/authPages/forgetPassword/ForgetPasswordPage';
import { DashboardLayout } from './Layouts/DashboardLayout';
import PrivateRoute from './PrivateRoute/PrivateRoute';
import ManageInventoryPage from './Pages/dashboardPages/inventoryPages/ManageInventoryPage';
import UploadInventoryPage from './Pages/dashboardPages/inventoryPages/UploadInventoryPage';
import ManageLooksPage from './Pages/dashboardPages/looksPage/ManageLooksPage';
import CalibrateInventoryPage from './Pages/dashboardPages/inventoryPages/CalibrateInventoryPage';
import ChangePasswordPage from './Pages/settingsPages/ChangePasswordPage';
import ModelImagePage from './Pages/settingsPages/ModelImagePage';
import BackgroundImagePage from './Pages/settingsPages/BackgroundImagePage'
import CustomizationPage from './Pages/settingsPages/CustomizationPage';
import InviteUserPage from './Pages/settingsPages/InviteUserPage';
import BrandLogoPage from './Pages/settingsPages/BrandLogoPage';
import CreateLooksPage from './Pages/dashboardPages/looksPage/CreateLooksPage';
import SignupPage from './Pages/authPages/signup/SignupPage'
import DashboardPage from './Pages/homepage/DashboardPage';
import AnalyticsPage from './Pages/homepage/AnalyticsPage';
import CreateCategoryPage from './Pages/settingsPages/CreateCategoryPage';
import IntensitySliderSettingsPage from './Pages/settingsPages/IntensitySliderSettingsPage';
import CreateLookSuccessPage from './Pages/dashboardPages/looksPage/CreateLookSuccessPage';
import AddToCartPage from './Pages/settingsPages/AddToCartPage';
import ConsentInformationPage from './Pages/settingsPages/ConsentInformationPage';
import ResetPasswordPage from './Pages/authPages/forgetPassword/ResetPasswordPage';
import ColorRecommendationPage from './Pages/settingsPages/ColorRecommendationPage';
import FiltersPage from './Pages/settingsPages/FiltersPage';
import FindYourShadesPage from './Pages/dashboardPages/findYourShadesPage/FindYourShadesPage';
import SkinAnalysisPage from './Pages/settingsPages/SkinAnalysisPage';
import SkinConcernMessagingPage from './Pages/settingsPages/SkinConcernMessagingPage';
import SkinAnalyticsDashboardPage from './Pages/analytics/SkinAnalysisDashboardPage';

function App() {
  return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<PrivateRoute Component={DashboardLayout} />}>
            <Route path="/home" element={<PrivateRoute Component={DashboardPage} />}></Route>
            <Route path="/skin-analytics-dsahboard" element={<PrivateRoute Component={SkinAnalyticsDashboardPage} />}></Route>
            <Route path="/analytics" element={<PrivateRoute Component={AnalyticsPage} />}></Route>
            <Route path="/upload-inventory" element={<PrivateRoute Component={UploadInventoryPage} />}></Route>
            <Route path="/manage-looks" element={<PrivateRoute Component={ManageLooksPage} />}></Route>
            <Route path="/create-looks" element={<PrivateRoute Component={CreateLooksPage} />}></Route>
            <Route path="/calibrate-inventory" element={<PrivateRoute Component={CalibrateInventoryPage} />}></Route>
            <Route path="/inventory" element={<PrivateRoute Component={ManageInventoryPage} />}></Route>
            <Route path="/find-your-shades" element={<PrivateRoute Component={FindYourShadesPage} />}></Route>
            <Route exact path='/'element={ <Navigate to={"/home"}/> } ></Route>
          </Route>
          <Route exact path="/settings" element={<PrivateRoute Component={DashboardLayout} />}>
            <Route path="/settings/invite-user" element={<PrivateRoute Component={InviteUserPage} />}></Route>
            <Route path="/settings/change-password" element={<PrivateRoute Component={ChangePasswordPage} />}></Route>
            <Route path="/settings/model-images" element={<PrivateRoute Component={ModelImagePage} />}></Route>
            <Route path="/settings/brand-logo" element={<PrivateRoute Component={BrandLogoPage} />}></Route>
            <Route path="/settings/background-image" element={<PrivateRoute Component={BackgroundImagePage} />}></Route>
            <Route path="/settings/customization" element={<PrivateRoute Component={CustomizationPage} />}></Route>
            <Route path="/settings/category" element={<PrivateRoute Component={CreateCategoryPage} />}></Route>
            <Route path="/settings/intensity-slider-settings" element={<PrivateRoute Component={IntensitySliderSettingsPage} />}></Route>
            <Route path="/settings/cart-info" element={<PrivateRoute Component={AddToCartPage} />}></Route>
            <Route path="/settings/consent-information" element={<PrivateRoute Component={ConsentInformationPage} />}></Route>
            <Route path="/settings/color-recommendation" element={<PrivateRoute Component={ColorRecommendationPage} />}></Route>
            <Route path="/settings/filters" element={<PrivateRoute Component={FiltersPage} />}></Route>
            <Route path="/settings/skin-analysis" element={<PrivateRoute Component={SkinAnalysisPage} />}></Route>
            <Route path="/settings/skin-concern-messaging" element={<PrivateRoute Component={SkinConcernMessagingPage} />}></Route>
          </Route>
          <Route path="/authentication/sign-in" element={<SignInPage />}></Route>
          <Route path="/authentication/sign-up" element={<SignupPage />}></Route>
          <Route path="/authentication/forgot-password" element={<ForgetPasswordPage />}></Route>
          <Route path="/authentication/reset-password" element={<ResetPasswordPage />}></Route>
          <Route path="/look-success" element={<CreateLookSuccessPage />}></Route>
        </Routes>
      </BrowserRouter>
  );
}

export default App;
