import { BACKEND_HOST } from "../constants/backend";
import { AMPLITUDE_ID, AMPLITUDE_SECRET } from "../constants/amplitude";
class ApiService {
    constructor() {
      this.brandId = JSON.parse(localStorage.getItem('brandData'))?.brandDetails?.brand_id;
    }

    adminLogin = async (params) => {
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(params),
        };
        let fetchedData = await fetch(
          `${BACKEND_HOST}/user/loginForBrand`,
          requestOptions
        )
          .then((response) => {
              return response.json();
          })
          .catch((err) => {
            console.log(err);
          }
        );
        if (fetchedData) {
          return fetchedData;
        }
    };

    getCategories = async () => {
      let fetchedData = await fetch(
        `${BACKEND_HOST}/category/beauty/${this.brandId}`,
        )
        .then((response) => {
          if (response.status===200) {
            return response.json();
          }
          throw new Error(response);
        })
        .catch((err) => {
          console.log(err);
        }
      );
      if (fetchedData && fetchedData.data) {
        return fetchedData.data;
      }
    };

    getCategoryProductsAndVariants = async(category,page, disable=1)=>{
      const params=
          {
              limit:1000000,
              "filter_field":{
                "disable":disable
            }
          }
          const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(params),
          };
      let fetchedData = await fetch(
        `${BACKEND_HOST}/product/beauty/${this.brandId}/categories/${category}/inventories`,
        requestOptions)
        .then((response) => {
          if (response.status===200) {
            return response.json();
          }
          throw new Error(response);
        })
        .catch((err) => {
          console.log(err);
        })
      ;
      if(fetchedData) {
        return fetchedData;
      }
    }

    getCategoryProducts = async (category,allProducts,disable)=>{
      let token = JSON.parse(localStorage.getItem("authTokens")).accessToken;
      let fetchedData = await fetch(
        `${BACKEND_HOST}/product/parent/brand/${this.brandId}/category/${category}/page/1/limit/1000000?allProduct=${allProducts}&parentHandle=&disable=${disable}`,
        { headers: { Authorization: `Bearer ${token}` } }
      )
        .then((response) => {
          if (response.status===200) {
            return response.json();
          }
          throw new Error(response);
        })
        .catch((err) => {
          console.log(err);
        })
      ;
      if(fetchedData) {
        return fetchedData;
      }
    }

    getProductVariants = async (productId,allVariants,disable)=>{
      let token = JSON.parse(localStorage.getItem("authTokens")).accessToken;
      let fetchedData = await fetch(
        `${BACKEND_HOST}/product/variant/productId/${productId}/brand/${this.brandId}/page/1/limit/1000000?disable=${disable}&allVariant=${allVariants}`,
        { headers: { Authorization: `Bearer ${token}` } }
      )
        .then((response) => {
          if (response.status===200) {
            return response.json();
          }
          throw new Error(response);
        })
        .catch((err) => {
          console.log(err);
        })
      ;
      if(fetchedData && fetchedData.data) {
        return fetchedData.data;
      }
    }

    createCategory = async (params) => {
        let token = JSON.parse(localStorage.getItem("authTokens")).accessToken;
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" , Authorization: `Bearer ${token}`,},
          body: JSON.stringify(params),
        };
        let fetchedData = await fetch(
          `${BACKEND_HOST}/category/beauty/create`,
          requestOptions
        )
          .then((response) => {
              return response.json();
          })
          .catch((err) => {
            console.log(err);
          }
        );
        if (fetchedData) {
          return fetchedData;
        }
    };

    uploadProductSheet = async (formData) => {
      const requestOptions = {
        method: "POST",
        body: formData,
      };
      let fetchedData = await fetch(
        `${BACKEND_HOST}/product/beauty/add/${this.brandId}`,
        requestOptions
      )
        .then((response) => {
            return response.json();
        })
        .catch((err) => {
          console.log(err);
        });
      if (fetchedData) {
        return fetchedData;
      }
    };

    downloadProductSheet = async ()=>{
        let token = JSON.parse(localStorage.getItem("authTokens")).accessToken;
        let fetchedData = await fetch(
          `${BACKEND_HOST}/product/csvdownload/brand/${this.brandId}`,
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((response) => {
            return response;
        })
        .catch((err) => {
          console.log(err);
        });
        if (fetchedData) {
          return fetchedData;
        }
    }

    getLooks =async ()=>{
      let fetchedData = await fetch(
        `${BACKEND_HOST}/looks/beauty/${this.brandId}`,
        )
        .then((response) => {
          if (response.status===200) {
            return response.json();
          }
          throw new Error(response);
        })
        .catch((err) => {
          console.log(err);
        }
      );
      if (fetchedData && fetchedData.data) {
        return fetchedData.data;
      }
    }

    createLook = async (params) => {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(params),
      };
      let fetchedData = await fetch(
        `${BACKEND_HOST}/looks/beauty/create`,
        requestOptions
      )
        .then((response) => {
          if (response.status===200) {
            return response.json();
          }
          throw new Error(response);
        })
        .catch((err) => {
          console.log(err);
        }
      );
      if (fetchedData && fetchedData.data) {
        return fetchedData.data;
      }
    }

    createCollectionLookMapping = async(params)=>{
      let accessToken = JSON.parse(localStorage.getItem("authTokens")).accessToken;
      const requestOptions = {
        method: "POST",
        body:JSON.stringify(params),
        headers: { 
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json' 
        }
      };
      let fetchedData = await fetch(
        `${BACKEND_HOST}/lookcollection/mapping/brand/${this.brandId}`,
        requestOptions
      )
        .then((response) => {
          if (response.status===200) {
            return response.json();
          }
          throw new Error(response);
        })
        .catch((err) => {
          console.log(err);
        }
      );
      if (fetchedData && fetchedData.data) {
        return fetchedData.data;
      }
    }

    mapProductToLooks = async(params)=>{
      let accessToken = JSON.parse(localStorage.getItem("authTokens")).accessToken;
      const requestOptions = {
        method: "POST",
        headers: { 
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json' 
        },
        body: JSON.stringify(params),
      };
      let fetchedData = await fetch(
        `${BACKEND_HOST}/looks/beauty/brand/${this.brandId}/mapping/variant`,
        requestOptions
      )
        .then((response) => {
          if (response.status===200) {
            return response.json();
          }
          throw new Error(response);
        })
        .catch((err) => {
          console.log(err);
        }
      );
      if (fetchedData && fetchedData.data) {
        return fetchedData.data;
      }
    }

    enableOrDisableOrEditVariants = async(params)=>{
      let accessToken = JSON.parse(localStorage.getItem("authTokens")).accessToken;
      const requestOptions = {
        method: "POST",
        body:JSON.stringify(params),
        headers: { 
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json' 
        }
      };
      let fetchedData = await fetch(
        `${BACKEND_HOST}/product/beauty/edit/brand/${this.brandId}`,
        requestOptions
      )
        .then((response) => {
          if (response.status===200) {
            return response.json();
          }
          throw new Error(response);
        })
        .catch((err) => {
          console.log(err);
        }
      );
      if (fetchedData && fetchedData.data) {
        return fetchedData.data;
      }
    }

    enableOrDisableProducts = async(params,subBrandId)=>{
      let accessToken = JSON.parse(localStorage.getItem("authTokens")).accessToken;
      const requestOptions = {
        method: "POST",
        body:JSON.stringify(params),
        headers: { 
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json' 
        }
      };
      let fetchedData = await fetch(
        `${BACKEND_HOST}/product/beauty/edit/brand/${this.brandId}`,
        requestOptions
      )
        .then((response) => {
          if (response.status===200) {
            return response.json();
          }
          else return response
        })
        .catch((err) => {
          console.log(err);
        }
      );
      if (fetchedData) {
        return fetchedData;
      }
    }

    deleteProducts = async (params)=>{
      let accessToken = JSON.parse(localStorage.getItem("authTokens")).accessToken;
      const requestOptions = {
        method: "DELETE",
        headers: { 
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json' 
        },
        body:JSON.stringify(params)
      };
      let fetchedData = await fetch(
        `${BACKEND_HOST}/product/byProductId/brand/${this.brandId}`,
        requestOptions
      )
        .then((response) => {
          if (response.status===200) {
            return response.json();
          }
          throw new Error(response);
        })
        .catch((err) => {
          console.log(err);
        }
      );
      if (fetchedData && fetchedData.data) {
        return fetchedData.data;
      }
    }

    deleteVariants = async(params)=>{
      let accessToken = JSON.parse(localStorage.getItem("authTokens")).accessToken;
      const requestOptions = {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        },
        body:JSON.stringify(params)
      };
      let fetchedData = await fetch(
        `${BACKEND_HOST}/product/byVariantId/brand/${this.brandId}`,
        requestOptions
      )
        .then((response) => {
          if (response.status===200) {
            return response.json();
          }
          throw new Error(response);
        })
        .catch((err) => {
          console.log(err);
        }
      );
      if (fetchedData && fetchedData.data) {
        return fetchedData.data;
      }
    }

    amplitudeAnalytics = async (event_type, interval, metric, start_date, end_date, is_grouped_by, group_by_value, group_by_type) => {
      let groupBy = `,"group_by":[`
      group_by_value.forEach((value, index) => {
        groupBy += `{"group_type":"User", "type":"${group_by_type}", "value":"${value}"}`
        if(index === group_by_value.length - 1) {
          groupBy += `]`
        } else {
          groupBy += ','
        }
      })
  
      const headers= { 
          Authorization: `Basic ${
                btoa(AMPLITUDE_ID + ":" + AMPLITUDE_SECRET)
          }`,
        }
      let fetchedData =await fetch(
        `https://api.annotations.mirrar.io/proxy?path=${encodeURIComponent(`https://amplitude.com/api/2/events/segmentation?e={"event_type":"${event_type}","filters":[{"subprop_type":"event","subprop_key":"brandId","subprop_op":"is","subprop_value":["${this.brandId}"]}]${is_grouped_by ? groupBy : ''}}&m=${metric}&start=${start_date}&end=${end_date}&i=${interval}`)}`,
        {headers:headers}
      )
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          }
          throw new Error(response);
        })
        .catch((err) => {
          console.log(err);
        });
        if (fetchedData && fetchedData.data && fetchedData.data) {
          return fetchedData.data;
        }
    }

    fetchBrandSettings = async()=>{
      let fetchedData = await fetch(
        `${BACKEND_HOST}/setting/beauty/${this.brandId}`,
        )
        .then((response) => {
          if (response.status===200) {
            return response.json();
          }
          throw new Error(response);
        })
        .catch((err) => {
          console.log(err);
        }
      );
      if (fetchedData && fetchedData.data) {
        return fetchedData.data;
      }
    }

    inviteUser = async (params)=>{
      let accessToken = JSON.parse(localStorage.getItem("authTokens")).accessToken;
      const requestOptions = {
        method: "POST",
        body:JSON.stringify(params),
        headers: { 
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json' 
        }
      };
      let fetchedData = await fetch(
        `${BACKEND_HOST}/user/signup/brand/${this.brandId}`,
        requestOptions
      )
        .then((response) => {
            return response.json();
        })
        .catch((err) => {
          console.log(err);
        }
      );
      if (fetchedData ) {
        return fetchedData;
      }
    }

    getUploadedImageUrl = async(formData)=>{
      let accessToken = JSON.parse(localStorage.getItem("authTokens")).accessToken;
    //   console.log("formData",formData);
      const requestOptions = {
        method: "POST",
        body: formData,
        headers: { 
          Authorization: `Bearer ${accessToken}`,
        }
      };
      let fetchedData = await fetch(
        `${BACKEND_HOST}/upload/brand/${this.brandId}`,
        requestOptions
      )
      .then((response) => {
        if (response.status===200) {
          return response.json();
        }
        throw new Error(response);
      })
      .catch((err) => {
        console.log(err);
      });
      if (fetchedData && fetchedData.data) {
        return fetchedData.data;
      }
    }

    createSettings = async (params)=>{
      let accessToken = JSON.parse(localStorage.getItem("authTokens")).accessToken;
      const requestOptions = {
        method: "POST",
        body:JSON.stringify(params),
        headers: { 
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json' 
        }
      };
      let fetchedData = await fetch(
        `${BACKEND_HOST}/setting/beauty/create`,
        requestOptions
      )
        .then((response) => {
          if (response.status===200) {
            return response.json();
          }
          throw new Error(response);
        })
        .catch((err) => {
          console.log(err);
        }
      );
      if (fetchedData && fetchedData.data) {
        return fetchedData.data;
      }
    }

    updateSettings = async (params)=>{
      let accessToken = JSON.parse(localStorage.getItem("authTokens")).accessToken;
      const requestOptions = {
        method: "POST",
        body:JSON.stringify(params),
        headers: { 
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json' 
        }
      };
      let fetchedData = await fetch(
        `${BACKEND_HOST}/setting/beauty/update`,
        requestOptions
      )
        .then((response) => {
          if (response.status===200  ) {
            return response.json();
          }else if(response.status === 403){
            return response
          }
          throw new Error(response);
        })
        .catch((err) => {
          console.log(err);
        }
      );
      if (fetchedData) {
        return fetchedData;
      }
    }

    uploadImageFolder = async (params)=>{
      let accessToken = JSON.parse(localStorage.getItem("authTokens")).accessToken;
      const requestOptions = {
        method: "POST",
        body: params,
        headers: { 
          Authorization: `Bearer ${accessToken}`,
        }
      };
      let fetchedData = await fetch(
        `${BACKEND_HOST}/product/upload/brand/${this.brandId}`,
        requestOptions
      )
      .then((response) => {
        if (response.status===200) {
          return response.json();
        }
        throw new Error(response);
      })
      .catch((err) => {
        console.log(err);
      });
      if (fetchedData) {
        return fetchedData;
      }
    }

    fetchLooksCategories = async () => {
      let fetchedData = await fetch(
        `${BACKEND_HOST}/lookcollection/brand/${this.brandId}/page/1/limit/200?all=true`,
        )
        .then((response) => {
          if (response.status===200) {
            return response.json();
          }
          throw new Error(response);
        })
        .catch((err) => {
          console.log(err);
        }
      );
      if (fetchedData && fetchedData.data) {
        return fetchedData.data;
      }
    };

    createLooksCategory = async (params)=>{
      let accessToken = JSON.parse(localStorage.getItem("authTokens")).accessToken;
      const requestOptions = {
        method: "POST",
        body:JSON.stringify(params),
        headers: { 
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json' 
        }
      };
      let fetchedData = await fetch(
        `${BACKEND_HOST}/lookcollection/brand/${this.brandId}`,
        requestOptions
      )
        .then((response) => {
          if (response.status===200) {
            return response.json();
          }
          throw new Error(response);
        })
        .catch((err) => {
          console.log(err);
        }
      );
      if (fetchedData && fetchedData.data) {
        return fetchedData.data;
      }
    }

    getLooksInCollection = async ()=>{
        let token = JSON.parse(localStorage.getItem("authTokens")).accessToken;
        let fetchedData = await fetch(
          `${BACKEND_HOST}/lookcollection/brand/${this.brandId}/page/1/limit/1000`,
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          }
          throw new Error(response);
        })
        .catch((err) => {
          console.log(err);
        });
        if (fetchedData && fetchedData.data) {
          return fetchedData.data;
        }
    }

    getRecommendedProducts = async (category,color, similarity=95,colorUpperLimit=100,minDistanceBetween = 0)=>{
      let accessToken = JSON.parse(localStorage.getItem("authTokens")).accessToken;
      const params = {
        "filter_field":{
          "page":1,
          "similarity":Number(similarity),
          "colorUpperLimit":Number(colorUpperLimit),
          // "sort_field" : "color",
          // "sort_by" : "light",
          "minDistanceBetween": Number(minDistanceBetween)
        }
      }
      const requestOptions = {
        method: "POST",
        body:JSON.stringify(params),
        headers: { 
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json' 
        }
      };
      let fetchedData = await fetch(
        `${BACKEND_HOST}/product/beauty/${this.brandId}/category/${category}/recommendation/rgb(${color})/variants/v2`,
        requestOptions
      )
        .then((response) => {
          if (response.status===200 || response.status === 400) {
            return response.json();
          }
          throw new Error(response);
        })
        .catch((err) => {
          console.log(err);
        }
      );
      if (fetchedData && fetchedData.data) {
        return fetchedData.data;
      }
    }

    getLookProducts = async (key)=>{
        const params = {
            "filter_field":{
                "disable":0,
                "allLook" : 1
            }
        }

      const requestOptions = {
        method: "POST",
        body:JSON.stringify(params),
        headers: { 
          'Content-Type': 'application/json' 
        }
      };
      let fetchedData = await fetch(
        `${BACKEND_HOST}/product/beauty/${this.brandId}/look/${key}/inventories`,
        requestOptions
      )
        .then((response) => {
          if (response.status===200 || response.status === 400) {
            return response.json();
          }
          throw new Error(response);
        })
        .catch((err) => {
          console.log(err);
        }
      );
      if (fetchedData && fetchedData.data) {
        return fetchedData.data;
      }
    }

    getWishlistedProducts = async(startDate,endDate)=>{
      let fetchedData = await fetch(
        `${BACKEND_HOST}/wishlist/beauty/brand/${this.brandId}/lt/${endDate}/gt/${startDate}/page/1/limit/100`,
        )
        .then((response) => {
          if (response.status===200) {
            return response.json();
          }
          throw new Error(response);
        })
        .catch((err) => {
          console.log(err);
        }
      );
      if (fetchedData && fetchedData.data) {
        return fetchedData.data;
      }
    }

    getProductData = async(productCode)=>{
      let fetchedData = await fetch(
        `${BACKEND_HOST}/product/search/brand/${this.brandId}/${productCode}`,
        )
        .then((response) => {
          if (response.status===200) {
            return response.json();
          }
          throw new Error(response);
        })
        .catch((err) => {
          console.log(err);
        }
      );
      if (fetchedData && fetchedData.data) {
        return fetchedData.data;
      }
    }

    changePassword = async (params)=>{
      let accessToken = JSON.parse(localStorage.getItem("authTokens")).accessToken;
      const requestOptions = {
        method: "POST",
        body:JSON.stringify(params),
        headers: { 
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json' 
        }
      };
      let data = await fetch(
        `${BACKEND_HOST}/user/change-pswd/brand/${this.brandId}`,
        requestOptions
      )
        .then((response) => {
            return response.json();
        })
        .catch((err) => {
          console.log(err);
        }
      );
      if (data) {
        return data;
      }
    }

    forgetPassword = async(params)=>{
      const requestOptions = {
        method: "POST",
        body:JSON.stringify(params),
        headers: { 
          'Content-Type': 'application/json' 
        }
      };
      let fetchedData = await fetch(
        `${BACKEND_HOST}/user/authentication/forgot-password`,
        requestOptions
      )
      .then((response) => {
          return response.json();
      })
      .catch((err) => {
        console.log(err);
      });
      if (fetchedData) {
        return fetchedData;
      }
    }

    resetPassword = async(params)=>{
      const requestOptions = {
        method: "POST",
        body:JSON.stringify(params),
        headers: { 
          'Content-Type': 'application/json' 
        }
      };
      let fetchedData = await fetch(
        `${BACKEND_HOST}/user/authentication/reset-password`,
        requestOptions
      )
      .then((response) => {
          return response.json();
      })
      .catch((err) => {
        console.log(err);
      });
      if (fetchedData) {
        return fetchedData;
      }
    }

    getAllUsers = async()=>{
      let token = JSON.parse(localStorage.getItem("authTokens")).accessToken;
      let fetchedData = await fetch(
        `${BACKEND_HOST}/user/brand/${this.brandId}/users-list`,
        { headers: { Authorization: `Bearer ${token}` } }
      )
        .then((response) => {
            return response.json();
        })
        .catch((err) => {
          console.log(err);
        })
      ;
      if(fetchedData) {
        return fetchedData;
      }
    }

    removeUser = async(params)=>{
      let accessToken = JSON.parse(localStorage.getItem("authTokens")).accessToken;
      const requestOptions = {
        method: "POST",
        body:JSON.stringify(params),
        headers: { 
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json' 
        }
      };
      let data = await fetch(
        `${BACKEND_HOST}/user/remove/user`,
        requestOptions
      )
        .then((response) => {
            return response.json();
        })
        .catch((err) => {
          console.log(err);
        }
      );
      if (data) {
        return data;
      }
    }

    getFilters = async()=>{
      let fetchedData = await fetch(
        `${BACKEND_HOST}/availablefilter/brand/${this.brandId}`,
        )
        .then((response) => {
          return response.json();
        })
        .catch((err) => {
          console.log(err);
        }
      );
      if (fetchedData) {
        return fetchedData;
      }
    }

    upsertFilters = async (params)=>{
      let accessToken = JSON.parse(localStorage.getItem("authTokens")).accessToken;
      const requestOptions = {
        method: "POST",
        body:JSON.stringify(params),
        headers: { 
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json' 
        }
      };
        const response = await fetch(
          `${BACKEND_HOST}/availablefilter/brand/${this.brandId}`,
          requestOptions
        );
    
        if (response.ok) {
          const data = await response.json();
          return data;
        } else {
          return response;
        }
    }

    getYourShades = async(category)=>{
      let fetchedData = await fetch(
        `${BACKEND_HOST}/findYourShade/beauty/${this.brandId}/page/1/limit/200?category=${category}`,
        )
        .then((response) => {
          return response.json();
        })
        .catch((err) => {
          console.log(err);
        }
      );
      if (fetchedData) {
        return fetchedData;
      }
    }

    upsertShades = async(params)=>{
      const requestOptions = {
        method: "POST",
        body:JSON.stringify(params),
        headers: { 
          'Content-Type': 'application/json' 
        }
      };
      let fetchedData = await fetch(
        `${BACKEND_HOST}/findYourShade/beauty/create`,
        requestOptions
      )
      .then((response) => {
          return response.json();
      })
      .catch((err) => {
        console.log(err);
      });
      if (fetchedData) {
        return fetchedData;
      }
    }

    disableShades = async(params)=>{
      const requestOptions = {
        method: "POST",
        body:JSON.stringify(params),
        headers: { 
          'Content-Type': 'application/json' 
        }
      };
      let fetchedData = await fetch(
        `${BACKEND_HOST}/findYourShade/beauty/disable`,
        requestOptions
      )
      .then((response) => {
          return response.json();
      })
      .catch((err) => {
        console.log(err);
      });
      if (fetchedData) {
        return fetchedData;
      }
    }


    getSkinConcernSettings = async () => {
      try {
        const myHeaders = new Headers();
        let token = JSON.parse(localStorage.getItem("authTokens")).accessToken;
        myHeaders.append("Authorization", `Bearer ${token}`);
  
        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        };
  
        const output = await fetch(`${BACKEND_HOST}/skinAnalysis/get/brand/${this.brandId}`, requestOptions)
        if (output.status === 403) {
          return { error: "Forbidden access" };
        }
        const result = await output.json()
        return result?.data
      } catch (error) {
        console.log(error);
        return null
      }
    }
  
    setSkinConcernSettings = async (settingJson) => {
      if (!settingJson) return null;
      try {
        const myHeaders = new Headers();
        let token = JSON.parse(localStorage.getItem("authTokens")).accessToken;
        myHeaders.append("Authorization", `Bearer ${token}`);
        myHeaders.append("Content-Type", "application/json");
        const raw = JSON.stringify(settingJson)
        const requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
        };
  
        const output = await fetch(`${BACKEND_HOST}/skinAnalysis/upsert/brand/${this.brandId}`, requestOptions)
        if (output.status === 403) {
          return {status:403}
        }
        const result = await output.json()
        return result
      } catch (error) {
        console.log(error);
        return error
      }
    }

    searchVariant = async(category,searchText)=>{
      let fetchedData = await fetch(
        `${BACKEND_HOST}/product/beauty/${this.brandId}/category/${category}/search/term/${searchText}/page/1/limit/5`,
        )
        .then((response) => {
          return response.json();
        })
        .catch((err) => {
          console.log(err);
        }
      );
      if (fetchedData) {
        return fetchedData;
      }
    }

    deleteLook = async (lookId)=>{
      let accessToken = JSON.parse(localStorage.getItem("authTokens")).accessToken;
      const requestOptions = {
        method: "DELETE",
        headers: { 
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json' 
        },
      };
      let fetchedData = await fetch(
        `${BACKEND_HOST}/looks/beauty/look/${lookId}/brand/${this.brandId}`,
        requestOptions
      )
        .then((response) => {
          if (response.status===200  ) {
            return response.json();
          }else if(response.status === 403){
            return response
          }
          throw new Error(response);
        })
        .catch((err) => {
          console.log(err);
        }
      );
      if (fetchedData) {
        return fetchedData;
      }
    }
}

let apiService = new ApiService();

export { apiService as ApiService };